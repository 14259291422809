import { storageService } from '@proliance-ai/design-system';
import { ApplicationProduct, getLastPageStorageName, lastPageStorageName, RouteListType } from '@router';

export interface setLastPageStorageValueOptions {
  key?: string;
  value: ApplicationProduct | RouteListType;
}

export const setLastPageStorageValue = (options: setLastPageStorageValueOptions): void => {
  const {
    key = lastPageStorageName,
    value
  } = options;
  const storageName = getLastPageStorageName();

  const storageData = storageService.get<Record<string, ApplicationProduct | RouteListType>>(storageName);
  if (storageData !== null) {
    storageService.set(storageName, { ...storageData, [key]: value });
  } else {
    storageService.set(storageName, { [key]: value });
  }
};
