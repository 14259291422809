import { FC } from 'react';
import { ActivationFnFactory, NavigationOptions, State, StateMeta } from 'router5';
import { Params } from 'router5/dist/types/base';
import { Observable } from 'rxjs';
import { ComponentPermissionData } from '@proliance-ai/typings';
import { TitleOptions, TitleOptionsData } from '@proliance-ai/design-system';
import {
  Namespace,
  NamespaceData,
  RouteDictionary,
} from '@interfaces';
import { FullRouteListType, RouteListType } from '@router';

export interface RouteStateData extends IRouterMeta {
  [key: string]: any;
}

export interface IRouteState extends State {
  component: FC;
  data: RouteStateData;
  error: null | number;
  isRouted: boolean;
  pageType: PageType;
  isLoggedIn: boolean;
  redirected?: boolean;
}

export type RouteResolve = Record<string, (params: any) => Observable<any>>;

export type ComponentType = FC | IComponentPromiseFactory<any>;
export type ComponentTypeDictionary = Partial<{
  [key in RouteListType]: ComponentType;
}>;
export type RouteComponentType = ComponentType | ComponentTypeDictionary;

export interface RouteOptions {
  name: RouteListType;
  path?: RouteListType;
  component?: RouteComponentType;
  dynamic?: boolean;
  lazy?: boolean;
  params?: {
    path: string;
  };
  namespace?: NamespaceData;
  titleOptions?: TitleOptionsData;
  resolve?: RouteDictionary<RouteResolve> | RouteResolve;
  canActivate?: ActivationFnFactory;
  forwardTo?: string;
  defaultParams?: Params;
}
export interface IExportFC<T> {
  default: FC<T>;
}
export type IComponentPromiseFactory<T> = () => Promise<IExportFC<T>>;
export interface IRouterMeta {
  namespace: Namespace;
  pageTitle: string;
  permission: ComponentPermissionData;
}
export interface RouterMetaData {
  namespace: NamespaceData;
  pageTitle: string;
  permission: ComponentPermissionData;
  titleOptions?: TitleOptions;
}
export type ResolveFactory = (data: ResolverData) => Observable<any>;
export type ResolveFactoryDictionary = Record<string, ResolveFactory>;
export interface ResolverData {
  isLoggedIn: boolean;
  parameter: Record<string, string>;
  permission?: ComponentPermissionData;
}
export interface RouterDependency {
  lazy: boolean;
  dynamic: boolean;
  component?: RouteComponentType;
  resolve?: RouteDictionary<ResolveFactoryDictionary> | ResolveFactoryDictionary;
}

export enum PageType {
  private = 'private',
  both = 'both',
  public = 'public',
}

export type RedirectOptions = {
  route?: FullRouteListType;
  parameters?: Params;
  options?: NavigationOptions;
}

export interface RouteState extends State {
  name: FullRouteListType;
  isLoggedIn: boolean;
  isRouted: boolean;
  redirected?: boolean;
  pageType: PageType;
  meta: StateMeta;
  data: RouteStateData;
  error: null | number;
  component: null | FC;
}
